.layout {
    background: radial-gradient(50% 50% at 50% 50%, #63386a 0%, #310438 100%);
    background-size: cover;
}

.imageContainer {
    display: flex;
    justify-content: center;
}

.container {
    max-width: 408px;
    margin: auto;
}

.title {
    text-align: center;
    color: #626262;
    font-size: 30px;
    letter-spacing: -0.04em;
}
