.placeholdersContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.searchResultsList {
    max-height: 55vh;
    overflow-y: auto;
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 0 auto;
}

.searchResultsList::-webkit-scrollbar {
    width: 5px;
}

.searchResultsList::-webkit-scrollbar-track {
    background-color: #d9d9d9;
    border-radius: 100px;
}

.searchResultsList::-webkit-scrollbar-thumb {
    background-color: #179be5;
    border-radius: 100px;
}

.searchResultsListItem {
    background-color: #fafafa;
    padding: 8px;
    margin-bottom: 16px;
    border-radius: 6px;
    margin-right: 8px;
}

.searchResultsSubTitle {
    font-size: 0.75rem;
    font-weight: bold;
}

.searchResultsButtonContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
