.centeredContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.contentContainer {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 6px;
    padding: 14px 24px 16px 24px;
}

.dataContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.contents {
    border: 1px solid #d9d9d9;
    border-radius: 6px 0 0 6px;
    padding: 8px;
}

.chaptersList {
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 65vh;
    overflow-y: auto;
}

.chaptersList::-webkit-scrollbar {
    width: 5px;
}

.chaptersList::-webkit-scrollbar-track {
    background-color: #d9d9d9;
    border-radius: 100px;
}

.chaptersList::-webkit-scrollbar-thumb {
    background-color: #179be5;
    border-radius: 100px;
}

.versesContainer {
    border: 1px solid #d9d9d9;
    border-left: none;
    border-radius: 0 6px 6px 0;
    padding: 8px;
    display: flex;
    flex-direction: column;
}

.versesList {
    list-style: none;
    margin: 0 0 16px;
    max-height: 65vh;
    overflow-y: auto;
    padding: 0 16px 0 0;
}

.versesList::-webkit-scrollbar {
    width: 5px;
}

.versesList::-webkit-scrollbar-track {
    background-color: #d9d9d9;
    border-radius: 100px;
}

.versesList::-webkit-scrollbar-thumb {
    background-color: #179be5;
    border-radius: 100px;
}

.verseListItem {
    padding-bottom: 8px;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    min-height: 32px;
    padding-right: 32px;
    position: relative;
}

.verseListItem button {
    display: none;
    position: absolute;
    right: 0;
}

.verseListItem:hover button {
    display: block;
}

.visibleButton {
    display: block !important;
}

.selectedVersesList {
    max-height: 50vh;
    overflow-y: auto;
    padding: 16px;
    margin: 0;
    background: white;
    border-radius: 6px;
    list-style: none;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08);
}

.selectedVersesList::-webkit-scrollbar {
    width: 5px;
}

.selectedVersesList::-webkit-scrollbar-track {
    background-color: #d9d9d9;
    border-radius: 100px;
}

.selectedVersesList::-webkit-scrollbar-thumb {
    background-color: #179be5;
    border-radius: 100px;
}

.selectedVersesListItem {
    padding: 0;
    display: flex;
    margin-bottom: 8px;
    justify-content: space-between;
}

.selectedVersesListItem:last-child {
    margin-bottom: 0;
}
